
import { defineComponent, reactive, ref, Transition } from "vue";
import { ElSelect, ElOption, ElMessage } from "element-plus";
import style from "./style.module.less";
import { Service } from "@/api";

type GoodsListItemModel = {
  cardDays?: number;
  cardId?: string;
  cardName?: string;
} & Phoenix.Common.DiamondListItem;

export default defineComponent({
  name: "Recharge",
  setup() {
    const formRef = ref<HTMLDivElement>();
    const state = reactive({
      list: [] as GoodsListItemModel[],
      userId: "",
      userInfo: null as Phoenix.Common.UserInfo | null,
      source: "",
    });

    Service.CommonController.getGoods().then(({ data }) => {
      state.list = (data.diamondList as GoodsListItemModel[]).concat(
        data.monthlyCardList as GoodsListItemModel[]
      );
    });

    const searchUser = async () => {
      const { data } = await Service.CommonController.getUserById(state.userId);
      state.userInfo = data;
    };

    const selectItem = async (item: GoodsListItemModel) => {
      if (!state.userInfo) {
        ElMessage.error("请先输入用户ID搜索用户");
        return;
      }

      if (!state.source) {
        ElMessage.error("请选择充值渠道");
        return;
      }

      const { data } = await Service.CommonController.recharge({
        goodsId: item.diamondId! || item.cardId!,
        orderType: item.diamondId ? 1 : 2,
        platform: 3,
        source: Number(state.source),
        uid: state.userId,
        payType: 1,
      });

      formRef.value!.innerHTML = data.page;
      // @ts-ignore
      document.forms["punchout_form"].submit();
      console.log(data);
    };

    return () => (
      <div class={style.container}>
        <div ref={formRef}></div>
        <div class={style.main}>
          <div class={style.header}>
            <span class={style.title}>钻石充值</span>
            <Transition name="mode-fade" mode="out-in">
              {state.userInfo ? (
                <div class={style.userInfo}>
                  <img src={state.userInfo?.avatar} />
                  <div class={style.nickname}>{state.userInfo?.nickname}</div>
                  <button onClick={() => (state.userInfo = null)}>
                    切换用户
                  </button>
                </div>
              ) : (
                <div class={style.inputWrapper}>
                  <input
                    placeholder="请输入我是谜ID号"
                    v-model={state.userId}
                  />
                  <button onClick={searchUser}>确认</button>
                </div>
              )}
            </Transition>

            <ElSelect
              placeholder="请选择渠道"
              class={style.select}
              v-model={state.source}
            >
              <ElOption label="安卓" value="1"></ElOption>
              <ElOption label="IOS" value="2"></ElOption>
            </ElSelect>
          </div>
          <div class={style.body}>
            {state.list.map((x) => (
              <div
                class={style.item}
                key={x.diamondId || x.cardId}
                onClick={() => selectItem(x)}
              >
                <div
                  class={style.img}
                  style={{ backgroundImage: `url(${x.imgUrl})` }}
                />
                {x.diamondId ? (
                  <div class={style.count}>{x.origNumber}钻</div>
                ) : null}
                <div class={style.price}>¥{x.price}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
});
